<template>
  <div class="w-full flex flex-col">
    <div :class="getClass()" class="flex bg-white dark:bg-black">
      <div
        v-for="tab in tabs"
        :class="
          selectedTab === tab.value
            ? 'text-cs-light-blue border-b-2 border-cs-light-blue dark:border-cs-light-blue dark:text-cs-light-blue'
            : ' dark:text-white'
        "
        class="flex-1 py-5 cursor-pointer flex justify-center"
        @click="updateType(tab.value)"
      >
        <span class="font-roboto-normal text-sm md:text-base capitalize">{{ tab.text }}</span>
      </div>
    </div>
    <slot name="tab-body" v-bind="{selectedTab}" />
  </div>
</template>

<script lang="ts" setup>
interface ComponentProps {
  tabs: {text: string; value: string}[]
  updateTabHandler?: Function
  selected?: string
  hasBorder?: boolean
  extraClass?: string
}

const props = defineProps<ComponentProps>()

const selectedTab = ref<string>(props.selected || props.tabs[0].value || '')

const updateType = (tab: string) => {
  selectedTab.value = tab
  props.updateTabHandler?.(tab)
}

const getClass = () => {
  let className = props.hasBorder ? ' border-l border-r border-cs-light-grey dark:border-cs-medium-grey' : ''
  if (props.extraClass) {
    className += ' ' + props.extraClass
  }
  return className
}
</script>
